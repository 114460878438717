export const API_KEY = "kKP368dxD4kVg9KuwAElLOilqr5BaPhZ";
export const MAX_WIDTH_IMAGE_UPLOAD = 2000; // the smaller the image width resolution the faster the upload to validation
export const MAX_HEIGHT_IMAGE_UPLOAD = 2000; // // the smaller the image height resolution the faster the upload to validation
export const CURRENCY_SYMBOL = "$";
export const INTEGRATION_CLIENT = "Sephora Collection";
export const PLATFORM_CLIENT = "Sephora Collection";
export const UPLOADS_BUCKET = "mistrix-sessions-outputs";
export const THEME = "default";
export const GOOGLE_ANALYTICS_ID = "";
export const IS_ADD_TO_CART = false;
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '[::1]'; // IPv6 localhost
export const APP_DOMAIN = isLocalhost ? "https://default.mistrix.dev" : ""; // If developing on localhost, use dev domain for backend